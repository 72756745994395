import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {concatMap, from, Observable, of} from 'rxjs';
import {DocumentDashboardComponent} from '../../document-dashboard/document-dashboard.component';
import {HandleErrorService} from '../../../services/handle-error.service';
import {CustomTranslateService} from '../../../services/custom-translate.service';
import {FileKeyProperties, FilePropertyKey, FilePropertyRequest, FilePropertyValue} from '../../../models/fileKeyProperties.model';
import {DMSConfigService} from '../../../services/dmsconfig.service';
import {catchError} from 'rxjs/operators';
import {DocMgmtService} from '../../../services/doc-mgmt.service';
import {FileMetadata} from '../../../models/file-metadata.model';

@Component({
  selector: 'app-assignment-dialog',
  templateUrl: './assignment-dialog.component.html',
  styleUrls: ['./assignment-dialog.component.css']
})
export class AssignmentDialogComponent implements OnInit {
  static assignmentDoneEvent = new EventEmitter<FileMetadata>();

  private assignmentURL = `${this.dmsConfigService.getConfig().API_DMS_GATEWAY_URL}/files/`;

  properties: FileKeyProperties[] = [];
  selectedPropertyValues: FileKeyProperties[] = [];
  selectedPropVals: { [key: number]: FilePropertyValue[] } = {};
  private itemsToAdd: Set<FilePropertyValue> = new Set();
  private initSelectedPropVals: { [key: number]: FilePropertyValue[] } = {};
  private itemsToRemove: Set<FilePropertyValue> = new Set();


  constructor(
    public dialogRef: MatDialogRef<AssignmentDialogComponent>,
    private readonly handleErrorService: HandleErrorService,
    private readonly translationService: CustomTranslateService,
    private readonly dmsConfigService: DMSConfigService,
    private readonly docMgmtService: DocMgmtService,
    @Inject(MAT_DIALOG_DATA) public data: {
      message: string,
      propertyValuesList: FileKeyProperties[],
      fileId: number,
      selectedPropVals: any
      fileName: string,
      fileKey: string,
    },
    private fb: FormBuilder,
    private http: HttpClient,
  ) {
    this.properties = this.data.propertyValuesList.sort((fileProp1, fileProp2) => {
      if (!fileProp1.key || !fileProp2.key) {
        return 0;
      }
      return fileProp1.key.toString().localeCompare(fileProp2.key.toString());
    });
  }

  assignmentForm = this.fb.group({
    selectedPropVals: [this.selectedPropVals],
  });

  ngOnInit(): void {
    if (this.data.selectedPropVals && this.data.selectedPropVals.length > 0) {
      this.data.selectedPropVals.forEach((prop) => {
        const vals = [...prop.values];
        this.selectedPropertyValues.push({id: prop.key.id, key: prop.key.key, values: vals});
        this.selectedPropVals[prop.key.id] = vals;
        this.initSelectedPropVals[prop.key.id] = [...vals];
      });
    }
  }

  onPropertyValueSelected(propId: number, propKey: FilePropertyKey, selected: FilePropertyValue[]): void {
    const existingProperty = this.selectedPropertyValues.find((prop) => prop.id === propId);
    if (existingProperty) {
      existingProperty.values = selected;
      this.selectedPropVals[propId] = existingProperty.values;
    } else {
      const value = {id: propId, key: propKey, values: selected};
      this.selectedPropVals[propId] = selected;
      this.selectedPropertyValues.push(value);
    }
    this.assignmentForm.value.selectedPropVals = JSON.parse(JSON.stringify(this.selectedPropVals));
  }

  onCloseClick(propId: number, propValId: number): void {
    const propKey = this.selectedPropertyValues.find((prop) => prop.id === propId);
    if (propKey) {
      const removedValues = propKey.values.filter(val => val.id === propValId);
      propKey.values = propKey.values.filter(val => val.id !== propValId);
      this.selectedPropVals[propId] = propKey.values;
      this.assignmentForm.value.selectedPropVals = this.selectedPropVals;
      // Track items to remove
      removedValues.forEach(val => this.itemsToRemove.add(val));
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onAssign(): void {
    this.preparePropertyValues();
    const selectedPropVals = this.assignmentForm.value.selectedPropVals;
    const fileId = this.data.fileId;
    if (this.itemsToAdd?.size > 0 || this.itemsToRemove?.size > 0) {
      const requests = [];
      if (this.itemsToAdd?.size > 0) {
        requests.push(...this.assignPropertyValues(fileId, Array.from(this.itemsToAdd)));
      }
      if (this.itemsToRemove?.size > 0) {
        requests.push(...this.unAssignPropertyValues(fileId, Array.from(this.itemsToRemove)));
      }
      from(requests).pipe(
        concatMap(request => request.pipe(
          catchError(err => {
            console.error('Error in processing the assignment:', err);
            this.handleErrorService.displayErrorDialog(this.translationService.getTranslatedValue('unableToProcess'), 'Process Error');
            return of(null);
          })
        ))
      ).subscribe({
                    complete: () => {
                      const updateFileMedatada = this.prepareFileMetadataForPropagation(this.data.fileKey, selectedPropVals);
                      AssignmentDialogComponent.assignmentDoneEvent.emit(updateFileMedatada);
                      this.dialogRef.close(selectedPropVals);
                    }
                  });
    } else {
      this.dialogRef.close();
      DocumentDashboardComponent.refreshEvent.emit();
    }
  }

  private buildFileProperties(selectedPropVals: { [key: number]: FilePropertyValue[] }): FileKeyProperties[] {
    const updateProperties: FileKeyProperties[] = [];
    Object.keys(selectedPropVals).forEach(keyId => {
      const property = this.properties.find(prop => prop.id === +keyId);
      if (property && selectedPropVals[keyId].length > 0) {
        const filePropertyKey: FilePropertyKey = {
          id: property.id,
          global: false,
          key: property.key.toString()
        };
        updateProperties.push({
          id: +keyId,
          key: filePropertyKey,
          values: selectedPropVals[keyId]
        });
      }
    });
    return updateProperties;
  }

  private getUpdatedFileMetadata(fileKey: string, fileProperties: FileKeyProperties[], filesData: FileMetadata[]): FileMetadata {
    return this.updateFileProperties(fileKey, filesData, fileProperties);
  }

  private prepareFileMetadataForPropagation(fileKey: string, selectedPropVals: { [key: number]: FilePropertyValue[] }): FileMetadata {
    const fileProperties = this.buildFileProperties(selectedPropVals);
    // The file table data is formed in 2 parts, one is the fileMetadata and the other is fullFileMetadata
    // For updating the file properties, we need to check in both lists
    let updatedFileMetadata = this.getUpdatedFileMetadata(fileKey, fileProperties, this.docMgmtService.fileMetadata.value);
    if (!updatedFileMetadata) {
      updatedFileMetadata = this.getUpdatedFileMetadata(fileKey, fileProperties, this.docMgmtService.fullFileMetadata.value);
    }
    return updatedFileMetadata;
  }

  private updateFileProperties(fileKey: string, filesData: FileMetadata[], fileProperties: FileKeyProperties[]): FileMetadata {
    const fileMetadata = filesData.find(file => file.fileKey === fileKey);
    if (fileMetadata) {
      fileMetadata.properties = fileProperties;
    }
    return fileMetadata;
  }

  /**
   * Prepare the property values to be assigned or unassigned
   * based on the changes made by the user
   * @private
   */
  private preparePropertyValues() {
      this.itemsToAdd.clear();
      this.itemsToRemove.clear();
      const selectedPropVals = this.assignmentForm.value.selectedPropVals || {};
      Object.keys(selectedPropVals).map((propId) => {
        const propVals = selectedPropVals[propId];
        const initialPropVals = this.initSelectedPropVals[propId] || [];
        propVals.forEach((val) => {
          if (!initialPropVals.find((initialVal) => initialVal.id === val.id) && !this.itemsToAdd.has(val)) {
            this.itemsToAdd.add(val);
          }
        });
        initialPropVals.forEach((initialVal) => {
          if (!propVals.find((val) => val.id === initialVal.id)) {
            this.itemsToRemove.add(initialVal);
          }
        });
      });
  }

  private assignPropertyValues(fileId: number, filePropertyValues: FilePropertyValue[]): Observable<any> [] {
    const requests = [];
    filePropertyValues.forEach((value: { id: number; }) => {
      const formData: FilePropertyRequest = {valueId: value.id};
      const request = this.http.post(`${this.assignmentURL}${fileId}/property-values`, formData).pipe(
        catchError(err => {
            console.error(`Error in assigning property value:`, err);
            return of(null); // Return a null observable to continue the forkJoin
          }
        )
      );
      requests.push(request);
    });
    return requests;
  }

  private unAssignPropertyValues(fileId: number, filePropertyValues: FilePropertyValue[]): Observable<any> [] {
    const requests = [];
    filePropertyValues.forEach((value: { id: number; }) => {
      const request = this.http.delete(`${this.assignmentURL}${fileId}/property-values/${value.id}`).pipe(
        catchError(err => {
            console.error(`Error in unassigning property value:`, err);
            return of(null); // Return a null observable to continue the forkJoin
          }
        )
      );
      requests.push(request);
    });
    return requests;
  }
}
