import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DateAdapter} from '@angular/material/core';
import {EMPTY, Subject, takeUntil} from 'rxjs';
import {DocMgmtService} from '../../../services/doc-mgmt.service';
import {FileTransfer} from '../../../models/file-transfer';
import {UserService} from '../../../services/user.service';
import {TableConfig} from '../../shared/table/table-config/table-config';
import {TableComponent} from '../../shared/table/table.component';
import {DateRangeValidator, END_DATE_KEY, START_DATE_KEY} from '../../shared/date-range-validator';
import {CommonConfig} from '../../shared/config/common-config';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {AdminLoginService} from '../../../services/admin-login.service';
import Utils from '../../../utils/utils';
import {RowData} from '../../../models/rowdata.model';
import {catchError} from 'rxjs/operators';
import {HandleErrorService} from '../../../services/handle-error.service';
import {CustomTranslateService} from '../../../services/custom-translate.service';

enum FileType {
  Image = 'image',
  Video = 'video',
  Other = 'other',
}

@Component({
  selector: 'dms-incoming-documents',
  templateUrl: './incoming-documents.component.html',
  styleUrls: ['./incoming-documents.component.css'],
})
export class IncomingDocumentsComponent implements OnInit, OnDestroy {
  private static readonly mobileColumns = ['fileDisplayName', 'name', 'date'];
  private static readonly webColumns = ['fileDisplayName', 'name', 'driverName', 'activity', 'taskName', 'taskReference', 'date'];

  @ViewChild('incomingDocumentsTable') incomingDocumentsTable: TableComponent;
  @ViewChild('startDateInput') startDateInput: ElementRef;
  @ViewChild('endDateInput') endDateInput: ElementRef;
  @ViewChild('searchInput') searchInput: any;
  @Output() showFilterPage = new EventEmitter();
  @Input() startDateFilter: Date;
  @Input() endDateFilter: Date;

  tableConfig: TableConfig;
  commonConfig: CommonConfig;
  tableData: any[] = [];
  filterForm: FormGroup;
  isWebView: boolean;
  filterValue: string;
  private readonly stop$: Subject<void> = new Subject<void>();

  constructor(
    private readonly fb: FormBuilder,
    private readonly docMgmtService: DocMgmtService,
    private readonly dateAdapter: DateAdapter<Date>,
    private readonly userService: UserService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly adminLoginService: AdminLoginService,
    private readonly handleErrorService: HandleErrorService,
    private readonly translateService: CustomTranslateService
  ) {
    this.dateAdapter.setLocale(this.userService.getLocale());
    this.createCommonConfig();
    this.setScreenSize();
    this.initializeForm();
  }

  ngOnInit(): void {
    this.subscribeToCustomerSelectionChange();
    this.tableConfig = this.buildTableConfig();
    this.getDocuments();
  }

  private subscribeToCustomerSelectionChange(): void {
    this.adminLoginService.customerSelectionChanged$
      .pipe(takeUntil(this.stop$))
      .subscribe(() => {
        this.getDocuments();
      });
  }

  setScreenSize() {
    this.breakpointObserver.observe(Breakpoints.XSmall).subscribe((result) => {
      this.isWebView = !result.matches;
    });
  }

  initializeForm() {
    this.filterForm = this.fb.group(
      {
        startDate: [null],
        endDate: [null],
        fileName: [null],
      },
      {validator: DateRangeValidator.dateRangeValidator(this.commonConfig)}
    );
  }

  createCommonConfig() {
    if (!this.commonConfig) {
      this.commonConfig = new CommonConfig();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.createCommonConfig();
    this.setConfigurationOnChange();
  }

  private setConfigurationOnChange(): void {
    this.commonConfig.startDate = this.startDateFilter
      ? this.startDateFilter
      : this.commonConfig.startDate;

    this.commonConfig.endDate = this.endDateFilter
      ? this.endDateFilter
      : this.commonConfig.endDate;
  }

  private buildTableConfig(): TableConfig {
    const tableConfig = new TableConfig();
    tableConfig.showCheckbox = false;
    tableConfig.showSearchBar = false;

    tableConfig.displayedColumnNames = this.isWebView ? IncomingDocumentsComponent.webColumns : IncomingDocumentsComponent.mobileColumns;

    const downloadAction = Utils.buildDownloadAction(this.translateService);
    const previewAction = Utils.buildPreviewAction(this.translateService);

    tableConfig.actionMenuList = [downloadAction, previewAction];

    return tableConfig;
  }

  getDocuments() {
    if (this.filterForm.valid) {
      this.docMgmtService
        .getIncomingDocuments(
          this.filterForm.get('fileName').value,
          this.commonConfig.startDate.getTime(),
          this.commonConfig.endDate.getTime()
        )
        .pipe(takeUntil(this.stop$))
        .subscribe(
          (fileTransfers: FileTransfer[]) => {
            const data = new Array();
            for (let i = 0; i < fileTransfers.length; i++) {
              const fileDisplayName = fileTransfers[i].fileName;
              const iconName = this.getFileIconName(fileDisplayName);
              data.push({
                id: fileTransfers[i].id,
                fileDisplayName: fileTransfers[i].fileName,
                driverName: fileTransfers[i].driverName,
                name: fileTransfers[i].terminalName,
                date: new Date(fileTransfers[i].timeStamp).toLocaleString(
                  this.userService.getLocale(),
                  {
                    timeZone: this.userService.getTimezone(),
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                  }
                ),
                taskName: fileTransfers[i].taskName,
                taskReference: fileTransfers[i].taskRef,
                activity: fileTransfers[i].activityTypeName,
                canPreview: Utils.isPreviewable(fileTransfers[i].fileName.split('.').pop().toLowerCase()),
                iconName: iconName,
              });
            }
            this.tableData = data;
          },
          (error) => {
            this.tableData = [];
          }
        );
    } else {
      this.tableData = [];
    }
  }

  private getFileIconName(fileName: string): string {
    const fileType = fileName.split('.').pop().toLowerCase();

    if (Utils.isImage(fileType)) {
      return FileType.Image;
    } else if (Utils.isVideo(fileType)) {
      return FileType.Video;
    }
    return FileType.Other;
  }

  onSearch(fileName: string) {
    this.filterForm.get('fileName').setValue(fileName);
    this.getDocuments();
  }

  onStartDateChanged(startDate: Date) {
    this.filterForm.get(START_DATE_KEY).setValue(startDate);
    this.getDocuments();
  }

  onEndDateChanged(endDate: Date) {
    this.filterForm.get(END_DATE_KEY).setValue(endDate);
    this.getDocuments();
  }

  protected gridAction(data: { type: string; row: RowData }): void {
    switch (data?.type) {
      case Utils.ACTION_DOWNLOAD:
        Utils.handleDownload$(
          this.docMgmtService.getFileData$(data.row.id),
          data.row.fileDisplayName
        )
          .pipe(
            takeUntil(this.stop$),
            catchError(() => {
              this.handleErrorService.displayErrorDialog(
                this.translateService.getTranslatedValue('downloadUnavailable'),
                ''
              );
              return EMPTY;
            })
          )
          .subscribe(() => this.incomingDocumentsTable.onActionComplete());
        break;

      case Utils.ACTION_PREVIEW:
        Utils.handlePreview$(
          this.docMgmtService.getFileData$(data.row.id),
          data.row.fileDisplayName
        )
          .pipe(
            takeUntil(this.stop$),
            catchError(() => {
              this.handleErrorService.displayErrorDialog(
                this.translateService.getTranslatedValue('previewUnavailable'),
                ''
              );
              return EMPTY;
            })
          )
          .subscribe(() => this.incomingDocumentsTable.onActionComplete());
        break;
    }
  }

  onResetClick() {
    this.initializeForm();
    this.startDateInput.nativeElement.value = null;
    this.endDateInput.nativeElement.value = null;
  }

  filter() {
    this.showFilterPage.emit(true);
  }

  ngOnDestroy() {
    this.stopSubscriptions();
  }

  private stopSubscriptions(): void {
    this.stop$.next();
    this.stop$.complete();
  }
}
