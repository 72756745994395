import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ElementRef,
  ViewChild,
  Input,
} from "@angular/core";
import {
  DateRangeValidator,
  START_DATE_KEY,
  END_DATE_KEY,
} from "../date-range-validator";
import { CommonConfig } from "../../shared/config/common-config";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DocMgmtService } from "../../../../app/services/doc-mgmt.service";
import { ReloadDataService } from "../../../../app/services/reload-data.service";

@Component({
  selector: "dms-date-filter",
  templateUrl: "./date-filter.component.html",
  styleUrls: ["./date-filter.component.css"],
})
export class DateFilterComponent implements OnInit {
  @Input() isIncomingDocTab: boolean;
  @ViewChild("startDateInput") startDateInput: ElementRef;
  @ViewChild("endDateInput") endDateInput: ElementRef;

  @Input() filterValue: string;
  @Output() closeEvent = new EventEmitter();

  commonConfig: CommonConfig;
  filterForm: FormGroup;
  FILE_TAB_INDEX: number = 0;
  INCOMINGDOC_TAB_INDEX: number = 3;
  @Output() filterValueEvent = new EventEmitter();

  constructor(
    private readonly fb: FormBuilder,
    private readonly docMgmtService: DocMgmtService,
    private readonly reloadDataService: ReloadDataService
  ) {
    this.createCommonConfig();
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  createCommonConfig() {
    if (!this.commonConfig) {
      this.commonConfig = new CommonConfig();
    }
  }
  initializeForm(): void {
    this.filterForm = this.fb.group(
      {
        startDate: [null],
        endDate: [null],
        fileName: [null],
      },
      { validator: DateRangeValidator.dateRangeValidator(this.commonConfig) }
    );
  }

  reset(): void {
    this.filterValueEvent.emit({
      startDate: this.commonConfig.startDate,
      endDate: this.commonConfig.endDate,
      pageNumber: 0,
      filterValue: this.filterValue,
    });
    this.docMgmtService.changePageValue();
    this.initializeForm();
    this.startDateInput.nativeElement.value = null;
    this.endDateInput.nativeElement.value = null;
    this.reloadDataService.setShouldReload(true);
    this.closeFilter();
  }

  closeFilter() {
    const tabIndex = this.isIncomingDocTab
      ? this.INCOMINGDOC_TAB_INDEX
      : this.FILE_TAB_INDEX;

    this.closeEvent.emit(tabIndex);
  }

  filter(): void {
    this.filterValueEvent.emit({
      startDate: this.commonConfig.startDate,
      endDate: this.commonConfig.endDate,
      pageNumber: 0,
      filterValue: this.filterValue,
    });

    const startDate = this.filterForm.get("startDate").value;
    const endDate = this.filterForm.get("endDate").value;

    if (startDate === null || endDate === null) {
      this.filterForm.setErrors({ missingDateRange: true });
      return;
    }
    if (this.filterForm.errors != null) {
      return;
    }

    this.closeFilter();
    this.reloadDataService.setShouldReload(true);
    if (!this.isIncomingDocTab) {
      this.docMgmtService.getFileMetadata({
        page: 0,
        filterValue: this.filterValue,
        startDate: this.commonConfig.startDate?.getTime(),
        endDate: this.commonConfig.endDate?.getTime(),
      });
    }
    this.reloadDataService.setShouldReload(false);
  }

  onStartDateChanged(startDate: Date): void {
    if (startDate !== undefined) {
      this.commonConfig.pageNumber = 0;
      this.filterForm.get(START_DATE_KEY).setValue(startDate);
    }
  }

  onEndDateChanged(endDate: Date): void {
    if (endDate !== undefined) {
      this.commonConfig.pageNumber = 0;
      this.filterForm.get(END_DATE_KEY).setValue(endDate);
    }
  }
}
