import {FileKeyProperties} from './fileKeyProperties.model';

export class FileMetadata {
  fileKey: string;
  fileType: string;
  fileName: string;
  createdDate: string;
  formattedFileSize: string;
  iconName: string;
  canPreview = false;
  linkId: number;
  assignedAt: string;
  isCheckboxDisabled: boolean;
  fileSize: number;
  properties: FileKeyProperties[];
}
