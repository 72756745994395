<div class="grid-filter" *ngIf="filterForm">
  <form [formGroup]="filterForm">
    <mat-error *ngIf="filterForm.errors?.startDateGreaterThanEndDate">
      {{ "startDateGreaterThanEndDate" | translate }}</mat-error
    >
    <mat-error *ngIf="filterForm.errors?.timeExceed">
      {{ "timeExceed" | translate }}</mat-error
    >
    <mat-error *ngIf="filterForm.errors?.startDateNotSelected">
      {{ "startDateNotSelected" | translate }}</mat-error
    >
    <mat-error *ngIf="filterForm.errors?.missingDateRange">
      {{ "missingDateRange" | translate }}</mat-error
    >
    <div class="filter-page">
      <div class="date-form">
        <div>
          <span>
            <i class="modus-icons" aria-hidden="true" (click)="closeFilter()"
              >arrow_back</i
            >
          </span>
          <span class="filter-header"> {{ "filter" | translate }} </span>
        </div>
        <div class="date-div">
          <span class="datepicker-span-start">
            <modus-date-picker>
              <modus-date-input
                #startDateInput
                type="start"
                label="{{ 'startDate' | translate }}"
                format="dd-mm-yyyy"
                allowed-chars-regex="[\d-]"
                show-calendar-icon="true"
                placeholder="{{ 'selectStartDate' | translate }}"
                (valueChange)="onStartDateChanged(startDateInput.value)"
              ></modus-date-input>
            </modus-date-picker>
          </span>

          <span class="datepicker-span">
            <modus-date-picker>
              <modus-date-input
                #endDateInput
                type="end"
                label="{{ 'endDate' | translate }}"
                format="dd-mm-yyyy"
                allowed-chars-regex="[\d-]"
                show-calendar-icon="true"
                placeholder="{{ 'selectEndDate' | translate }}"
                (valueChange)="onEndDateChanged(endDateInput.value)"
              ></modus-date-input>
            </modus-date-picker>
          </span>
        </div>
      </div>
    </div>
    <div class="buttons-div">
      <span>
        <modus-button
          button-style="outline"
          color="secondary"
          class="resetFilter"
          (buttonClick)="reset()"
          size="large"
        >
          {{ "reset" | translate }}
        </modus-button>
      </span>
      <span>
        <modus-button
          color="primary"
          class="filterButton"
          (buttonClick)="filter()"
          size="large"
        >
          {{ "filter" | translate }}
        </modus-button>
      </span>
    </div>
  </form>
</div>
