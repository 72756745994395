<div class="fixed-width-container">
  <mat-label class="select-label">{{ label | translate }}</mat-label>
  <mat-select class="select-tag" disableOptionCentering
              [formControl]="multiSelectCtrl"
              [compareWith]="compareTwoObjValues"
              multiple
              placeholder="{{ 'selectFromOptions' | translate }}"
              #opts
  >
    <app-search-input [searchCtrl]="searchCtrl"></app-search-input>
    <div>
      <div *ngIf="searchCtrl.value && !opts.options.length" class="no-data">
        {{ "searchNoResults" | translate }} "{{ searchCtrl.value }}".
      </div>
      <mat-option
        *ngFor="let value of filteredValues | async"
        [value]="value"
        (onSelectionChange)="onSelectionChange($event)"
      >{{ value.value | translate }}
      </mat-option
      >
    </div>
  </mat-select>
</div>
