<div class="input-container">
  <input
    matInput
    #input
    placeholder="{{ 'search' | translate }}"
    [formControl]="searchCtrl"
    (keydown)="onKeydown($event, input)"
  />
  <button mat-icon-button matSuffix *ngIf="searchCtrl.value" (click)="clearSearch()">
    <mat-icon>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="mi-solid mi-close" viewBox="0 0 24 24">
        <path d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59 7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12 5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4"/>
      </svg>
    </mat-icon>
  </button>
</div>
