import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FileKeyProperties} from '../models/fileKeyProperties.model';

@Injectable({
  providedIn: 'root'
})
export class PropertyDataTransferService {
  private propertyValuesListSubject = new BehaviorSubject<FileKeyProperties[]>([]);
  propertyValuesList$ = this.propertyValuesListSubject.asObservable();


  setPropertyValuesList(propertyValuesList: FileKeyProperties[]) {
    this.propertyValuesListSubject.next(propertyValuesList);
  }

  getPropertyValuesList(): FileKeyProperties[] {
    return this.propertyValuesListSubject.getValue();
  }
}
