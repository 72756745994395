import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder} from '@angular/forms';
import {FileKeyProperties, FilePropertyValue} from '../../../models/fileKeyProperties.model';
import {AssignmentDialogComponent} from '../assignment-dialog/assignment-dialog.component';

@Component({
             selector: 'upload-file-assignment',
             templateUrl: './upload-file-assignment-dialog.component.html',
             styleUrls: ['./upload-file-assignment-dialog.component.scss']
           })
export class UploadFileAssignmentDialogComponent implements OnInit {

  @Output() tagsAssigned = new EventEmitter<FileKeyProperties[]>();

  properties: FileKeyProperties[] = [];
  selectedPropertyValues: FileKeyProperties[] = [];
  selectedPropVals: { [key: number]: FilePropertyValue[] } = {};
  assignmentForm = this.fb.group({selectedPropVals: [this.selectedPropVals]});

  constructor(
    public dialogRef: MatDialogRef<AssignmentDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: {
      message: string,
      subMessage: string,
      propertyValuesList: FileKeyProperties[],
      fileId: number,
      selectedPropVals: any
    }
  ) {
    this.properties = this.data.propertyValuesList.sort((fileProp1, fileProp2) => {
      if (!fileProp1.key || !fileProp2.key) {
        return 0;
      }
      return fileProp1.key.toString().localeCompare(fileProp2.key.toString());
    });
  }

  ngOnInit(): void {
    if (this.data.selectedPropVals && this.data.selectedPropVals.length > 0) {
      this.data.selectedPropVals.forEach((prop) => {
        const vals = [...prop.values];
        this.selectedPropertyValues.push({id: prop.key.id, key: prop.key.key, values: vals});
        this.selectedPropVals[prop.key.id] = vals;
      });
    }
  }

  onPropertyValueSelected(property: FileKeyProperties, selected: FilePropertyValue[]): void {
    const existingProperty = this.selectedPropertyValues.find((prop) => prop.id === property.id);
    if (existingProperty) {
      existingProperty.values = selected;
      this.selectedPropVals[property.id] = existingProperty.values;
    } else {
      const value = {id: property.id, key: property.key, values: selected};
      this.selectedPropVals[property.id] = selected;
      this.selectedPropertyValues.push(value);
    }
    this.assignmentForm.value.selectedPropVals = JSON.parse(JSON.stringify(this.selectedPropVals));
  }

  onCloseClick(propId: number, propValId: number): void {
    const propKey = this.selectedPropertyValues.find((prop) => prop.id === propId);
    if (propKey) {
      propKey.values = propKey.values.filter(val => val.id !== propValId);
      this.selectedPropVals[propId] = propKey.values;
      this.assignmentForm.value.selectedPropVals = this.selectedPropVals;
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onAssign(): void {
    this.tagsAssigned.emit(this.selectedPropertyValues);
    this.dialogRef.close();
  }

  onSkip(): void {
    this.tagsAssigned.emit([]);
    this.dialogRef.close();
  }
}
