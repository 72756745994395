import {IncomingDocumentsComponent} from './incoming-documents/incoming-documents.component';
import {TerminalService} from '../../services/terminal.service';
import {Subject, takeUntil} from 'rxjs';
import {DocMgmtService} from '../../services/doc-mgmt.service';
import {GroupComponent} from './group/group.component';
import {UnitComponent} from './unit/unit.component';
import {FileComponent} from './file/file.component';
import {RightPanelService} from '../../services/right-panel.service';
import {UserService} from '../../services/user.service';
import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorResponse, HttpEventType} from '@angular/common/http';
import {FileMetadata} from '../../models/file-metadata.model';
import Utils from '../../utils/utils';
import {CustomTranslateService} from '../../services/custom-translate.service';
import {TidUserProfile} from '../../models/user-profile.model';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {CommonConfig} from '../shared/config/common-config';
import {TranslateService} from '@ngx-translate/core';
import {AdminLoginService} from '../../services/admin-login.service';
import {CustomerService} from '../../services/customer.service';
import {Customer} from '../../models/customer.model';
import {MatDialog} from '@angular/material/dialog';
import {PropertyDataTransferService} from '../../services/property-data-transfer.service';
import {FileKeyProperties} from '../../models/fileKeyProperties.model';
import {UploadFileAssignmentDialogComponent} from '../shared/upload-file-assignment-dialog/upload-file-assignment-dialog.component';

@Component({
  selector: 'document-dashboard',
  templateUrl: './document-dashboard.component.html',
  styleUrls: ['./document-dashboard.component.scss'],
})
export class DocumentDashboardComponent implements OnInit, OnDestroy {
  static refreshEvent: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('panel', {static: false}) set setPanelView(
    panelView: MatSidenav
  ) {
    if (panelView) {
      this.rightPanelService.setRightPanelInstance(panelView);
    }
  }

  @ViewChild('fileView', {static: false}) set setFileView(
    fileView: FileComponent
  ) {
    if (fileView) {
      this.fileView = fileView;
    }
  }

  @ViewChild('unitView', {static: false}) set setUnitView(
    unitView: UnitComponent
  ) {
    if (unitView) {
      this.unitView = unitView;
    }
  }

  @ViewChild('groupView', {static: false}) set setGroupView(
    groupView: GroupComponent
  ) {
    if (groupView) {
      this.groupView = groupView;
    }
  }

  @ViewChild('incomingDocumentsView', {static: false})
  set setIncomingDocumentsView(
    incomingDocumentsView: IncomingDocumentsComponent
  ) {
    if (incomingDocumentsView) {
      this.incomingDocumentsView = incomingDocumentsView;
    }
  }

  @ViewChild('fileUpload', {static: false}) fileUpload: ElementRef;
  @ViewChild('startDateInput') startDateInput: ElementRef;
  @ViewChild('endDateInput') endDateInput: ElementRef;

  fileView: FileComponent;
  unitView: UnitComponent;
  groupView: GroupComponent;
  incomingDocumentsView: IncomingDocumentsComponent;
  selectedTab = 0;
  disableTab = true;
  showDialog = false;
  files = [];
  minimized: boolean;
  dialogHeight = 200;
  failedCount = 0;
  successCount = 0;
  uploadedCount = 0;
  maxFileSize: number;
  maxFileSizeInMb: number;
  uploadedFiles: FileMetadata[] = [];
  uploadSuccess: boolean;
  userDetailesLoaded = false;
  isWebView: boolean;
  showFilterPage = false;
  headerPosition = 'above';
  commonConfig: CommonConfig;
  filterValue: string;
  startDateFilter: Date;
  endDateFilter: Date;
  pageNumber: number;
  searchFilterValue = '';
  isIncomingDocTab: boolean;
  INCOMINGDOC_TAB_INDEX = 3;
  errorWithSizeTranslation: string;
  showFilter = false;
  showFilterApplied = false;
  availableColumns: string[] = ['fileName',
      'createdDate',
      'country',
      'department',
      'documenttype',
      'language',
      'formattedFileSize',
    'actionColumn'];
    selectedColumns: string[] = ['fileName', 'createdDate', 'formattedFileSize', 'actionColumn'];

  private readonly stop$: Subject<void> = new Subject<void>();
  private readonly DEFAULT_FILE_SIZE = 10000;
  private readonly KB_TO_MB = 1024;
  protected customerNotSelected: boolean;
  private inProgress = false;
  private readonly ACTION_COLUMN = 'actionColumn';

  constructor(
    private userService: UserService,
    private rightPanelService: RightPanelService,
    private docMgmtService: DocMgmtService,
    private readonly translateService: CustomTranslateService,
    private readonly terminalService: TerminalService,
    private readonly breakpointObserver: BreakpointObserver,
    private translate: TranslateService,
    private readonly adminLoginService: AdminLoginService,
    private readonly customerService: CustomerService,
    private dialog: MatDialog,
    private readonly propertyDataTransferService: PropertyDataTransferService,
  ) {
    this.setScreenSize();
    this.commonConfig = new CommonConfig();
    translate.addLangs(['en', 'klingon']);
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngOnInit(): void {
    const storedColumns = sessionStorage.getItem('selectedColumns');
    if (storedColumns) {
      this.selectedColumns = JSON.parse(storedColumns);
    }
    localStorage.removeItem('filterValues');
    this.subscribeToCustomerSelectionChange();
    this.initializeForm();
    this.loadCustomerDetails();

    if (!this.docMgmtService.fullFileMetadata.value) {
      this.docMgmtService.getFullFileMetadata();
    }

    this.docMgmtService.disableTab
      .pipe(takeUntil(this.stop$))
      .subscribe((value) => {
        this.disableTab = value;
      });

    DocumentDashboardComponent.refreshEvent.subscribe(() => {
      this.docMgmtService.getFileMetadata();
      this.rightPanelService.triggerFilterReset();
    });

    this.rightPanelService.filterEvent.subscribe((event) => {
      if (event.showFilter) {
        this.showFilter = event.showFilter;
      }
      if (event.reset !== undefined) {
        this.showFilterApplied = event.reset;
      }
      if (event.isFilterApplied !== undefined) {
        this.showFilterApplied = event.isFilterApplied;
      }
    });
  }

  onSelectionChange() {
    if (!this.selectedColumns.includes(this.ACTION_COLUMN)) {
      this.selectedColumns.push(this.ACTION_COLUMN);
    }
    this.fileView.onSelectionChange(this.selectedColumns);
  }
  private subscribeToCustomerSelectionChange(): void {
    this.adminLoginService.customerSelectionChanged$
      .pipe(takeUntil(this.stop$))
      .subscribe((isSelected) => {
        this.setCustomerNotSelected(!isSelected);
        if (this.customerNotSelected === false) {
          this.docMgmtService.fullFileMetadata.next([]);
          this.loadCustomerDetails();
          this.docMgmtService.getFileMetadata({
            filterValue: this.filterValue
          });
          this.docMgmtService.getFullFileMetadata();
        }
      });
  }

  private loadCustomerDetails = (): void => {
    this.customerService
      .getCustomerDetail$()
      .pipe(takeUntil(this.stop$))
      .subscribe((customer: Customer) => {
        this.setFileSizeAndLoadTable(customer);
      });
  }

  private setFileSizeAndLoadTable = (customer: Customer): void => {
    const maxDocSize = customer.maxDocSize || this.DEFAULT_FILE_SIZE;
    this.maxFileSizeInMb = Math.round(maxDocSize / this.KB_TO_MB);
    this.maxFileSize = maxDocSize * this.KB_TO_MB;
    this.translateAndSetErrorWithSize();
    this.loadAllTable(this.userService.getTidUserProfile());
  }

  private translateAndSetErrorWithSize(): void {
    this.translate.get('alert', {size: this.maxFileSizeInMb})
      .pipe(takeUntil(this.stop$))
      .subscribe(translatedMessage => {
        this.errorWithSizeTranslation = translatedMessage;
      });
  }

  private setCustomerNotSelected(value: boolean): void {
    this.customerNotSelected = value;
  }

  initializeForm(): void {
    this.searchFilterValue = this.filterValue;
  }

  setScreenSize() {
    this.breakpointObserver.observe(Breakpoints.XSmall).subscribe((result) => {
      if (result.matches) {
        this.isWebView = false;
        this.headerPosition = 'below';
      } else {
        this.isWebView = true;
        this.headerPosition = 'above';
      }
    });
  }

  refresh ()  {
    localStorage.removeItem('filterValues');
    this.rightPanelService.triggerFilterReset();
    if (this.selectedTab === 0) {
      this.resetAllFileFilters();
    }
    if (this.selectedTab === 1) {
      this.terminalService.getTerminals();
    }
    if (this.selectedTab === 2) {
      this.terminalService.getTerminals();
    }
    if (this.selectedTab === 3) {
      this.incomingDocumentsView.filterValue = '';
      this.incomingDocumentsView.onResetClick();
    }
  }

  resetAllFileFilters() {
    this.filterValue = '';
  }

  loadAllTable(tidUserProfile: TidUserProfile) {
    this.translateService
      .setLanguageTranslation(tidUserProfile?.language)
      .then(() => {
        this.userDetailesLoaded = true;
      });
  }

  onTabChange(tab) {
    this.selectedTab = tab?.index;
    if (this.selectedTab === this.INCOMINGDOC_TAB_INDEX) {
      this.closePanel();
    }
  }

  closePanel() {
    this.rightPanelService.closePanel();
    this.rightPanelService.isPanelOpen.next(false);
    this.showFilter = false;
    this.fileView.clearTableSelection();
    this.unitView.clearTableSelection();
    this.groupView.clearTableSelection();
    this.rightPanelService.selectedFile.next(null);
    this.rightPanelService.selectedUnit.next(null);
    this.rightPanelService.selectedGroup.next(null);
  }

  ngOnDestroy() {
    this.stopSubscriptions();
    this.rightPanelService.selectedFile.next(null);
    this.rightPanelService.selectedGroup.next(null);
    this.rightPanelService.selectedUnit.next(null);
    sessionStorage.clear();
  }

  private stopSubscriptions(): void {
    this.stop$.next();
    this.stop$.complete();
  }

  closeProgresssDialog() {
    this.showDialog = false;
  }

  minimizeDialog() {
    this.minimized = !this.minimized;
    this.minimized ? (this.dialogHeight = 0) : (this.dialogHeight = 200);
  }

  onUploadClick() {
    this.files = [];
    this.uploadedFiles = [];
    this.uploadedCount = 0;
    this.failedCount = 0;
    this.successCount = 0;
    this.uploadSuccess = false;
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (const file of fileUpload.files) {
        this.files.push({data: file, progress: 0});
      }
      if (this.files.length > 0) {
        const dialogRef = this.dialog.open(UploadFileAssignmentDialogComponent, {
          width: '1084px',
          height: '45%',
          panelClass: 'resizable-dialog',
          data: {
            message: this.translateService.getTranslatedValue('assignmentConfirmation'),
            propertyValuesList: this.propertyDataTransferService.getPropertyValuesList(),
            subMessage: (this.files.length === 1) ?
                        this.files[0].data.name :
                        `${this.files.length} ${this.translateService.getTranslatedValue('documentsSelected')}`
          }
        });
        dialogRef.componentInstance.tagsAssigned.subscribe((properties: FileKeyProperties[]) => {
          const propertyValuesIds = new Set(properties.flatMap(property => property.values)
                                                      .map(propertyValue => propertyValue.id));
          this.showDialog = true;
          this.setInProgress(true);
          this.uploadFiles(propertyValuesIds);
        });
      }
    };
    fileUpload.click();
  }

  private uploadFiles(propertyValuesIds: Set<number>): void {
    this.fileUpload.nativeElement.value = '';
    this.files.forEach((file) => {
      if (file.data.size < this.maxFileSize) {
        this.uploadFile(file, propertyValuesIds);
      } else {
        file.alert = true;
        this.uploadedCount = this.uploadedCount + 1;
        this.failedCount = this.failedCount + 1;
        if (this.uploadedCount === this.files.length) {
          this.setInProgress(false);
        }
      }
    });
  }
  uploadFile(file: any, propertyValuesIds: Set<number>) {
    const formData = new FormData();
    formData.append('file', file.data);
    if (propertyValuesIds.size > 0) {
      const propertiesIdsJson = JSON.stringify([...propertyValuesIds]);
      formData.append('propertyValueIds', new Blob([propertiesIdsJson], { type: 'application/json' }));
    }
    this.docMgmtService
      .upload(formData)
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this.uploadedCount = this.uploadedCount + 1;
          this.failedCount = this.failedCount + 1;
          file.failure = true;
          if (this.uploadedCount === this.files.length) {
            this.loadFiles(this.uploadedFiles);
            this.setInProgress(false);
          }
          return `${file.data.name} upload failed.`;
        })
      )
      .subscribe((event: any) => {
        if (typeof event === 'object') {
          this.uploadedCount = this.uploadedCount + 1;
          this.successCount = this.successCount + 1;
          file.success = true;
          const uploadedFile: FileMetadata = new FileMetadata();
          uploadedFile.fileKey = event.body.fileId;
          uploadedFile.fileType = file.data.type;
          uploadedFile.fileName = file.data.name;
          uploadedFile.createdDate = new Date().getTime().toString();
          uploadedFile.fileSize = file.data.size;
          uploadedFile.formattedFileSize = Utils.bytesToSize(file.data.size);
          this.uploadedFiles.push(uploadedFile);
          if (this.uploadedCount === this.files.length) {
            this.loadFiles(this.uploadedFiles);
            this.setInProgress(false);
          }
          if (
            this.uploadedCount === this.files.length &&
            this.failedCount === 0
          ) {
            this.uploadSuccess = true;
          }
        }
      });
  }

  loadFiles(uploadedFiles) {
    this.resetAllFileFilters();
    const formattedFiles = Utils.formatFiles(uploadedFiles);
    const fileData = this.docMgmtService.fullFileMetadata.value;
    const newFileList = formattedFiles.concat(fileData);
    this.docMgmtService.fullFileMetadata.next(newFileList);
  }

  filter() {
    this.showFilterPage = true;
    this.isIncomingDocTab = false;
  }

  closeFilterPage(event: number) {
    this.showFilterPage = false;
    this.selectedTab = event;
  }

  openFilterPanel() {
    this.showFilter = true;
    this.rightPanelService.isPanelOpen.next(true);
    this.rightPanelService.showFilter(this.showFilterApplied);
  }

  filterValueEvent(event): void {
    this.pageNumber = event.pageNumber;
    this.searchFilterValue = event.filterValue;
  }

  applyFilter(value) {
    this.filterValue = value?.trim()?.toLowerCase();
  }

  setFilterPage(event) {
    this.showFilterPage = event;
    this.isIncomingDocTab = true;
  }

  protected getInProgress(): boolean {
    return this.inProgress;
  }

  protected setInProgress(value: boolean): void {
    this.inProgress = value;
  }

  onFilterClose(event: MouseEvent) {
    event.stopPropagation();
    this.rightPanelService.triggerFilterReset();
  }
}
