import {BehaviorSubject} from 'rxjs';
import {EventEmitter, Injectable} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';

@Injectable()
export class RightPanelService {

  private rightPanel: MatSidenav;
  selectedFile = new BehaviorSubject<any>(null);
  selectedUnit = new BehaviorSubject<any>(null);
  selectedGroup = new BehaviorSubject<any>(null);
  isPanelOpen = new BehaviorSubject<boolean>(false);
  filterEvent = new EventEmitter<{ showFilter: boolean, reset: boolean, isFilterApplied: boolean }>();
  setRightPanelInstance(rightPanel: MatSidenav) {
    this.rightPanel = rightPanel;
  }
  openPanel() {
    if (this.rightPanel && !this.rightPanel.opened) {
      this.rightPanel?.open();
    }
  }

  closePanel() {
    this.rightPanel.close();
  }

  showFilter(isFilterApplied: boolean): void {
    this.filterEvent.emit({showFilter: true, reset: false, isFilterApplied: isFilterApplied});
    this.openPanel();
  }

  triggerFilterReset(): void {
    this.filterEvent.emit({showFilter: false, reset: true, isFilterApplied: false});
  }
}
