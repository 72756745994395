import {HandleErrorService} from './../../services/handle-error.service';
import {TranslatePipe} from './../../pipe/translate.pipe';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TableComponent} from './table/table.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {
  faCheckCircle,
  faDownload,
  faExclamationTriangle,
  faEye,
  faFileAlt,
  faFileImage,
  faFilePdf,
  faFileVideo,
  faLink,
  faSearch,
  faSortDown,
  faSortUp,
  faTimes,
  faUnlink,
} from '@fortawesome/free-solid-svg-icons';
import {MatSidenavModule} from '@angular/material/sidenav';
import {RightPanelService} from './../../services/right-panel.service';
import {faTimesCircle, faTrashAlt} from '@fortawesome/free-regular-svg-icons';
import {MatDialogModule} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {DateFilterComponent} from './date-filter/date-filter.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FiltersComponent} from '../document-dashboard/filters/filters.component';
import {MatSelectModule} from '@angular/material/select';
import {AssignmentDialogComponent} from './assignment-dialog/assignment-dialog.component';
import {MatChipsModule} from '@angular/material/chips';
import {MultiSelectComponent} from '../../components/organisms/multi-select/multi-select.component';
import {SearchInputComponent} from '../../components/atoms/search-input/search-input.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {UploadFileAssignmentDialogComponent} from './upload-file-assignment-dialog/upload-file-assignment-dialog.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
      TableComponent,
      TranslatePipe,
      ConfirmationDialogComponent,
      DateFilterComponent,
      AssignmentDialogComponent,
      MultiSelectComponent,
      SearchInputComponent,
      FiltersComponent,
      UploadFileAssignmentDialogComponent
  ],
  exports: [
    TableComponent,
    MatCardModule,
    MatTabsModule,
    MatButtonModule,
    FontAwesomeModule,
    MatSidenavModule,
    TranslatePipe,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    DateFilterComponent,
    AssignmentDialogComponent,
    MultiSelectComponent,
    SearchInputComponent,
    MatTooltipModule,
    FiltersComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatTabsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    FontAwesomeModule,
    MatSidenavModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    MatChipsModule,
    MatSelectModule,
    MatDividerModule,
    MatIconModule,
    MatTooltipModule,
    MatTooltipModule,
    MatFormFieldModule,
    ReactiveFormsModule
  ],
  entryComponents: [ConfirmationDialogComponent, AssignmentDialogComponent, MultiSelectComponent, SearchInputComponent, FiltersComponent],
  providers: [RightPanelService, HandleErrorService],
})
export class SharedModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(
      faFileAlt,
      faFilePdf,
      faFileImage,
      faFileVideo,
      faTimesCircle,
      faSearch,
      faTimes,
      faUnlink,
      faLink,
      faCheckCircle,
      faSortDown,
      faSortUp,
      faExclamationTriangle,
      faTrashAlt,
      faEye,
      faDownload
    );
  }
}
