<div class="dialog">
  <div class="dialog-header">
    <h4>{{ data.message }}</h4>
    <p class="sub-message">{{data.subMessage}}</p>
  </div>
  <div class="dialog-flex-content" style="overflow-y: auto">
    <form [formGroup]="assignmentForm">
      <div class="dialog-select-container">
        <ng-container *ngFor="let property of properties">
          <app-multi-select [label]="property.key" [optionsList]="property.values" [prefilledValues]="selectedPropVals[property.id]" (selectionChange)="onPropertyValueSelected(property, $event)"></app-multi-select>
        </ng-container>
      </div>
      <div class="chip-container" *ngIf="selectedPropertyValues.length > 0 ">
        <ng-container *ngFor="let property of selectedPropertyValues">
          <span *ngIf="property.values.length > 0 ">
            <modus-chip *ngFor="let propval of property.values" [value]="propval.value | translate" show-close (closeClick)="onCloseClick(property.id,propval.id)"></modus-chip>
          </span>
        </ng-container>
      </div>
    </form>
  </div>
  <div class="dialog-footer">
    <modus-button color="tertiary" (click)="onCancel()">{{ "cancel" | translate }}</modus-button>
    <modus-button color="secondary" (click)="onSkip()">{{"skip" | translate }}</modus-button>
    <modus-button color="primary" type="submit" (click)="onAssign()">{{"assignTags" | translate }}</modus-button>
  </div>
</div>
