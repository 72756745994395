import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {forkJoin, mergeMap, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {FileKeyProperties, FilePropertyKey, FilePropertyValue, PageableResponse} from '../models/fileKeyProperties.model';
import {DMSConfigService} from './dmsconfig.service';

@Injectable({
  providedIn: 'root'
})
export class PropertyDataService {
  private propertyApiUrl = `${this.dmsConfigService.getConfig().API_DMS_GATEWAY_URL}/property-keys`;

  constructor(private http: HttpClient, private readonly dmsConfigService: DMSConfigService) {
  }

  getKeys(): Observable<FilePropertyKey[]> {
    return this.http.get<FilePropertyKey[]>(this.propertyApiUrl);
  }

  getValuesByKey(key: string): Observable<FilePropertyValue[]> {
    return this.http.get<PageableResponse<FilePropertyValue>>(`${this.propertyApiUrl}/${key}/property-values`).pipe(
      map((response: PageableResponse<FilePropertyValue>) => response.content)
    );
  }

  getValuesForAllKeys(): Observable<FileKeyProperties[]> {
    // @ts-ignore
    return this.getKeys().pipe(
      mergeMap((keys: any) => {
        const requests = keys.content.map((iKey: FilePropertyKey) =>
          this.getValuesByKey(iKey.id.toString()).pipe(
            map(values => ({id: iKey.id, key: iKey.key.toString(), values}))
          )
        );
        return forkJoin(requests);
      })
    );
  }
}
