export class TableConfig {
  /** Names of column to be displayed, value as array of string, Names should be as same as attribute */
  displayedColumnNames: string[] = [];
  /** To enable pagination for table, value as boolean */
  pagination: boolean = true;
  /** Number of record to be displayed per page, value as array of number */
  pageSizeOptions: Number[] = [10, 20];
  /** To enable go to first and got to last page in pagination for table, value as boolean */
  showPaginationFirstLastButtons: boolean = false;
  /** To enable row selection checkbox, value as boolean */
  showCheckbox: boolean = true;
  /** To show search bar, value as boolean */
  showSearchBar: boolean = true;
  /** To enable multiple row selection, value as boolean */
  multipleSelection: boolean = false;
  /** To enable header checkbox selection, value as boolean */
  headerCheckbox: boolean = false;
  /** To enable fixed header, value as boolean */
  stickyHeader: boolean = true;
  /**Menu to be displayed on right click of row */
  contextMenuDetails: Menu[] = [];
  /**Name of column which have icon with value */
  iconColumnName: string = "";
  /**table content height */
  contentHeight: number = 85;
  /**To perform action on row click */
  rowClickAction: boolean = false;
  /**Column to be right aligned */
  rightAlignColumnName: string[] = [];
  /** Names of actions to be displayed */
  actionMenuList: Menu[] = [];
  /**To show Start Date Picker, value as boolean */
  showStartDatePicker: boolean = false;
  /**To show End Date Picker, value as boolean */
  showEndDatePicker: boolean = false;
  /**Custom style for search field in detail pages */
  showCustomSearchField: boolean = false;
  /**Enable or disable infinite scrolling for TableComponent */
  infiniteScrollEnabled: boolean = false;
}

export class Menu {
  menuName: string;
  iconName: string;
  iconType: string;
  iconColor: string;
  translationKey: string;
}
