// `src/app/services/date-filter.service.ts`
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private startDateSource = new BehaviorSubject<Date>(null);
  private endDateSource = new BehaviorSubject<Date>(null);
  private propertyValuesIdsSource = new BehaviorSubject<number[]>([]);

  startDate$ = this.startDateSource.asObservable();
  endDate$ = this.endDateSource.asObservable();
  propertyValuesIds$ = this.propertyValuesIdsSource.asObservable();

  setStartDate(date: Date) {
    this.startDateSource.next(date);
  }

  setEndDate(date: Date) {
    this.endDateSource.next(date);
  }

  setPropertyValuesIds(ids: number[]) {
    this.propertyValuesIdsSource.next(ids);
  }

}
