import Utils from '../../app/utils/utils';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Link} from '../models/link.model';
import {FileMetadata} from '../models/file-metadata.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {DMSConfigService} from './dmsconfig.service';
import {PageResponse} from '../models/page-response-model';
import {PageInfo} from '../models/page-info.model';
import {ReloadDataService} from './reload-data.service';

interface PageParameters {
  page?: number;
  filterValue?: string;
  startDate?: number;
  endDate?: number;
  propertyValues?: number[];
}

@Injectable()
export class DocMgmtService {
  disableTab = new BehaviorSubject<boolean>(true);
  fileMetadata = new BehaviorSubject<FileMetadata[]>(null);
  fullFileMetadata = new BehaviorSubject<FileMetadata[]>(null);
  dmsUrl = this.dmsConfigService.getConfig().API_DMS_GATEWAY_URL;
  lastPage: boolean;
  defaultPage = 0;
  defaultSize = 20;
  fileConfigPageNumber = new BehaviorSubject<boolean>(false);
  private readonly START_DATE_PARAMETER_KEY = 'startDate';
  private readonly END_DATE_PARAMETER_KEY = 'endDate';
  private readonly KEYWORD_PARAMETER_KEY = 'keyword';
  private readonly PAGE_PARAMETER_KEY = 'page';
  private readonly SIZE_PARAMETER_KEY = 'size';
  private readonly PROPERTYVALUEIDS_PARAMETER_KEY = 'propertyValueIds';
  pageNumber = 0;
  isLastPage: boolean;

  constructor(
    private readonly http: HttpClient,
    private readonly dmsConfigService: DMSConfigService,
    private readonly reloadDataService: ReloadDataService) {
  }

  changePageValue() {
    this.fileConfigPageNumber.next(true);
  }

  getAssignDetails(documentId, ownerId, ownerType) {
    let params = new HttpParams();
    if (documentId) {
      params = params.append('documentId', documentId);
    }
    if (ownerId || ownerType) {
      params = params.append('ownerId', ownerId).set('ownerType', ownerType);
    }
    return this.http.get(`${this.dmsUrl}/links`, {
      params
    });
  }

  assignDocument(link: Partial<Link>) {
    return this.http
      .post(`${this.dmsUrl}/links`, link)
      .toPromise();
  }

  unAssignDocument(id: number) {
    return this.http
      .delete(`${this.dmsUrl}/links/` + id)
      .toPromise();
  }

  getFileMetadata(pageParameters?: PageParameters) {
    if (!this.reloadDataService.getShouldReload) {
      return;
    }

    let pagingParams = new HttpParams();
    if (pageParameters?.filterValue) {
      pagingParams = pagingParams.append(
        this.KEYWORD_PARAMETER_KEY,
        pageParameters?.filterValue
      );
    }
    if (pageParameters?.propertyValues) {
      const propertyValuesString = pageParameters.propertyValues.join(',');
      pagingParams = pagingParams.append(
        this.PROPERTYVALUEIDS_PARAMETER_KEY,
        propertyValuesString);
    }
    if (pageParameters?.page) {
      pagingParams = pagingParams.append(
        this.PAGE_PARAMETER_KEY,
        pageParameters?.page
      );
    } else {
      pagingParams = pagingParams.append(
        this.PAGE_PARAMETER_KEY,
        this.defaultPage
      );
    }
    pagingParams = pagingParams.append(
      this.SIZE_PARAMETER_KEY,
      this.defaultSize
    );
    if (pageParameters?.startDate && pageParameters?.endDate) {
      pagingParams = pagingParams
        .append(this.START_DATE_PARAMETER_KEY, pageParameters?.startDate)
        .append(this.END_DATE_PARAMETER_KEY, pageParameters?.endDate);
    }
    this.http
      .get(`${this.dmsUrl}/files`, {
        params: pagingParams
      })
      .subscribe(
        (pageResponse: PageResponse) => {
          const fileMetadata: FileMetadata[] = pageResponse.content;
          const pageInfo: PageInfo = pageResponse.page;
          this.lastPage = pageInfo.last;
          const formattedFiles: FileMetadata[] = Utils.formatFiles(fileMetadata);
          this.fileMetadata.next(formattedFiles);
          this.disableTab.next(false);
        },
        (error) => {
          this.fileMetadata.next([]);
          this.disableTab.next(false);
        }
      );
  }

  upload(formData) {
    return this.http.post<any>(
      `${this.dmsUrl}/files`,
      formData,
      {
        reportProgress: true,
        observe: 'events'
      }
    );
  }

  deleteFile$(fileKey: string): Observable<object> {
    return this.http.delete(
      `${this.dmsUrl}/files/` + fileKey
    );
  }

  downloadFile$(fileKey: string): Observable<Blob> {
    return this.http.get(
      `${this.dmsUrl}/files/` + fileKey,
      {
        responseType: 'blob'
      }
    );
  }

  getIncomingDocuments(fileName: string, startDate: number, endDate: number) {
    let params = new HttpParams()
      .set(this.START_DATE_PARAMETER_KEY, startDate.toString())
      .set(this.END_DATE_PARAMETER_KEY, endDate.toString());
    if (fileName) {
      params = params.append('fileName', fileName);
    }
    return this.http.get(`${this.dmsUrl}/incoming-files`, {
      params: params
    });
  }

  getFileData$(fileKey: string): Observable<Blob> {
    return this.http.get(`${this.dmsUrl}/incoming-files/` + fileKey, {
      responseType: 'blob'
    });
  }

  getFullFileMetadata() {
    let pagingParams = new HttpParams();

    pagingParams = pagingParams.append(
      this.PAGE_PARAMETER_KEY,
      this.pageNumber
    );

    pagingParams = pagingParams.append(this.SIZE_PARAMETER_KEY, 100);

    this.http
      .get(`${this.dmsUrl}/files`, {
        params: pagingParams
      })
      .subscribe(
        (pageResponse: PageResponse) => {
          const fileMetadata: FileMetadata[] = pageResponse.content;
          const pageInfo: PageInfo = pageResponse.page;
          this.isLastPage = pageInfo.last;
          const formattedFiles: FileMetadata[] = Utils.formatFiles(fileMetadata);
          if (this.fullFileMetadata.value) {
            const fileData = this.fullFileMetadata.value;
            const newFileList = formattedFiles.concat(fileData);
            this.fullFileMetadata.next(newFileList);
          } else {
            this.fullFileMetadata.next(formattedFiles);
          }
          if (!this.isLastPage) {
            this.pageNumber++;
            this.getFullFileMetadata();
          }
        },
        (error) => {
          this.fullFileMetadata.next([]);
        }
      );
  }
}
